/* eslint-disable no-void */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { ChangeEvent, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { InsuredUniversitySimulationValues, insuredUniversityValidationSchema, useInsuredUniversity } from './useInsuredUniversity';
import {
  ExtraCompensationInsured,
  ExtraCompensationInsuredOptions,
  GenderOptions,
  Genders,
  SevereDiseaseAssuranceRate,
} from '../../../utils/constants';
import {
  CheckboxField,
  DatepickerField,
  InputField, NumericFormatField, RadioField, SelectField, SliderField,
} from '../../Forms/Fields';
import { useMinimumCapital } from '../../../hooks/useMinimumCapital';
import { handleRutChange } from '../../../utils/formHandlers';
import { mapQueryParamsToRecommendationPayload } from '../../RecommenderForms/common/queryParams';
import { transformCLPToUF } from '../../../utils/user';

export function InsuredUniversityForm() {
  const { handleSubmitSimulation } = useInsuredUniversity();
  const [minimumCapital, setMinimumCapital] = useState(0);
  const [repeatSimulation, setRepeatSimulation] = useState(false);

  // Load query parameters and tries to fill the default values based on them
  const searchParams = new URLSearchParams(window.location.search);
  const valuesFromRecommender = mapQueryParamsToRecommendationPayload(searchParams);

  const methods = useForm<InsuredUniversitySimulationValues>({
    defaultValues: {
      beneficiary_date_of_birth: valuesFromRecommender.children[0] || undefined,
      date_of_birth: valuesFromRecommender.date_of_birth,
      gender: valuesFromRecommender.gender,
      death_insured_capital: transformCLPToUF(
        valuesFromRecommender.university_cost
        || valuesFromRecommender.goal
        || valuesFromRecommender.ideal_pension
      ),
      rentability_rate: 1,
      severe_diesase_assurance_rate: null,
      extra_compensation_insured: {
        itp: false,
        severe_disease: false,
      },
    },
    resolver: zodResolver(insuredUniversityValidationSchema(minimumCapital)),
  });

  const {
    date_of_birth,
    gender,
    extra_compensation_insured,
    severe_diesase_assurance_rate,
    death_insured_capital,
  } = methods.watch();
  const minimumDeathCapital = useMinimumCapital(
    {
      itp: extra_compensation_insured.itp,
      serious_illness_percentage: severe_diesase_assurance_rate?.value ?? 0,
      accidental_death: false, // Insured University does not provide `accidental_death` input
    },
    {
      date_of_birth,
      gender: gender as keyof typeof GenderOptions,
      product: 'INSURED_UNIVERSITY',

    }
  );

  const handleShowSevereDiseaseAssuranceRateSelect = (event: ChangeEvent<HTMLInputElement>) => {
    const defaultOption = SevereDiseaseAssuranceRate.asSelectOptions()[0];

    if (event.target.checked) {
      methods.setValue('severe_diesase_assurance_rate', defaultOption);
      return;
    }

    methods.setValue('severe_diesase_assurance_rate', null);
  };

  const handleTriggerErrors = () => methods.formState.errors;

  const onSubmit = async (values: InsuredUniversitySimulationValues) => {
    await handleSubmitSimulation(values);
    setRepeatSimulation(true);
  };

  useEffect(() => {
    setMinimumCapital(minimumDeathCapital);
  }, [minimumDeathCapital]);

  useEffect(() => {
    if (death_insured_capital >= minimumCapital) {
      methods.clearErrors('death_insured_capital');
    }
  }, [death_insured_capital, minimumCapital]);

  return (
    <FormProvider {...methods}>
      <form className="d-flex flex-column gap-2" onSubmit={methods.handleSubmit(onSubmit)}>
        <InputField<InsuredUniversitySimulationValues>
          id="rut"
          label="¿Cuál es su RUT?"
          path="rut"
          type="text"
          placeholder="11.111.111-1"
          onChange={handleRutChange}
        />

        <DatepickerField<InsuredUniversitySimulationValues>
          id="date_of_birth"
          label="¿Cuál es tu fecha de nacimiento?"
          path="date_of_birth"
        />

        <RadioField<InsuredUniversitySimulationValues>
          label="¿Cuál es tu género?"
          options={Genders.asRadioOptions({ omit: ['OTHER'] })}
          path="gender"
        />

        <DatepickerField<InsuredUniversitySimulationValues>
          id="beneficiary_date_of_birth"
          label="¿Cuál es la fecha de nacimiento del beneficiario?"
          path="beneficiary_date_of_birth"
        />

        <div>
          <div className="mb-2">
            <NumericFormatField<InsuredUniversitySimulationValues>
              hideErrorMessage
              id="rentability_rate"
              label="Rentabilidad real"
              path="rentability_rate"
              suffix=" %"
              min={1}
              max={5}
            />
          </div>

          <SliderField<InsuredUniversitySimulationValues>
            min={1}
            max={5}
            step={0.5}
            path="rentability_rate"
          />
        </div>

        <NumericFormatField<InsuredUniversitySimulationValues>
          id="death_insured_capital"
          label="Capital asegurado de fallecimiento / Objetivo de ahorro"
          path="death_insured_capital"
          prefix="UF "
          placeholder="UF"
          helpMessage={minimumDeathCapital ? `El capital mínimo debería ser de UF ${minimumDeathCapital}.` : undefined}
        />

        <CheckboxField<InsuredUniversitySimulationValues, Extract<ExtraCompensationInsuredOptions, 'itp' | 'several_diseases'>>
          label="¿Agrega otro capital asegurado?"
          options={ExtraCompensationInsured.asCheckboxOptions({ options: ['itp', 'several_diseases'] })}
          pathsMap={{
            several_diseases: 'extra_compensation_insured.severe_disease',
            itp: 'extra_compensation_insured.itp',
          }}
          onChangeMap={{
            several_diseases: handleShowSevereDiseaseAssuranceRateSelect,
          }}
          extraClassName="gap-5"
        />

        {extra_compensation_insured.severe_disease && (
          <SelectField<InsuredUniversitySimulationValues>
            id="severe_disease_assurance_rate"
            options={SevereDiseaseAssuranceRate.asSelectOptions()}
            path="severe_diesase_assurance_rate"
          />
        )}

        <button onClick={handleTriggerErrors} type="submit" className="btn btn-secondary text-white py-3 w-100 fw-bold mt-4">
          {repeatSimulation ? 'Volver a simular' : 'Simular'}
        </button>
      </form>
    </FormProvider>
  );
}
